import { ISelectOption } from '@sberbusiness/triplex/components/Select/Select';
import { ISuggestOption } from '@sberbusiness/triplex/components/Suggest/types';
import { CheckboxType } from './lTypes';
import { currencyData } from './Currency';
import CurrencyView from '../LComponents/CurrencyView';

export interface FDataUpdaterType {
  name: FILTERS_NAMES;
  value: ISelectOption | boolean | string;
}

export type FDataUpdaterFoo = (
  name: FILTERS_NAMES,
  value: ISelectOption | boolean | string | CheckboxType[],
) => void;

export enum CHAT_STATUSES {
  ALL_QUERY = '',
  ACTIVE_CHATS_ONLY = 'activeChatsOnly',
  EXCEPT_ACTIVE_CHATS = 'exceptActiveChats',
}

const { ALL_QUERY, ACTIVE_CHATS_ONLY, EXCEPT_ACTIVE_CHATS } = CHAT_STATUSES;

export enum FILTERS_NAMES {
  STATUS = 'status',
  NEWMESSAGES = 'hasNewMessages',
  ONLYMYQUOTE = 'onlyMy',
  SHOWCLOSING = 'withClosed',
  SWIFT = 'swift',
  QUOTE_ID = 'quoteId',
  TYPE = 'type',
  COUNTRY = 'country',
  SBERCLIENT = 'sberClient',
  CLIENT = 'client[]',
  AGENT = 'agent[]',
  MODERATOR = 'moderator[]',
  AMOUNT_FROM = 'amountFrom',
  AMOUNT_TO = 'amountTo',
  CURRENCY = 'currency',
  PERIOD_FROM = 'periodFrom',
  PERIOD_TO = 'periodTo',
}

export const statusOptions: ISelectOption[] = [
  { value: ALL_QUERY, label: 'Все' },
  { value: ACTIVE_CHATS_ONLY, label: 'Активные чаты' },
  { value: EXCEPT_ACTIVE_CHATS, label: 'Заявки без чатов' },
];

export enum SberClientsOptionsLIST {
  ALL_Q = '', //  Все заявки - по умолчанию
  NO_SBER_Q = 'noFlag', // Заявки без тега
  SBER_CLIENT_Q = 'sber', // Заявки с тегом
  FORMER_SBER_Q = 'notSber', // Заявки со снятым тегом
}

const { SBER_CLIENT_Q, FORMER_SBER_Q, NO_SBER_Q, ALL_Q } = SberClientsOptionsLIST;

export const SberClientsOptions: ISelectOption[] = [
  { value: ALL_Q, label: 'Все заявки' },
  { value: SBER_CLIENT_Q, label: 'Заявки с тегом' },
  { value: FORMER_SBER_Q, label: 'Заявки со снятым тегом' },
  { value: NO_SBER_Q, label: 'Заявки без тега' },
];

export enum TYPE_OPTIONS {
  ALL_T = '',
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

const { ALL_T, INCOMING, OUTGOING } = TYPE_OPTIONS;

export const OperationTypeOptions: ISelectOption[] = [
  { value: ALL_T, label: 'Все типы операции' },
  { value: INCOMING, label: 'Получение в РФ (Экспорт)' },
  { value: OUTGOING, label: 'Перевод из РФ (Импорт)' },
];

export const currencyListFilter = [
  { label: 'Все валюты', value: '' },
  ...currencyData.map(({ value, label }) => ({
    value,
    label: `${value} ${label}`,
    labelReactNode: <CurrencyView value={value} label={label} />,
  })),
];

export type FiltersDataType = {
  [FILTERS_NAMES.STATUS]: CheckboxType[];
  [FILTERS_NAMES.NEWMESSAGES]: boolean;
  [FILTERS_NAMES.ONLYMYQUOTE]: boolean;
  [FILTERS_NAMES.SHOWCLOSING]: boolean;
  [FILTERS_NAMES.SWIFT]: string;
  [FILTERS_NAMES.QUOTE_ID]: string;
  [FILTERS_NAMES.TYPE]: ISelectOption;
  [FILTERS_NAMES.COUNTRY]: ISuggestOption;
  [FILTERS_NAMES.SBERCLIENT]: ISelectOption;
  [FILTERS_NAMES.CLIENT]: ISuggestOption;
  [FILTERS_NAMES.AGENT]: ISuggestOption;
  [FILTERS_NAMES.MODERATOR]: ISuggestOption;
  [FILTERS_NAMES.AMOUNT_FROM]: string;
  [FILTERS_NAMES.AMOUNT_TO]: string;
  [FILTERS_NAMES.CURRENCY]: ISelectOption;
  [FILTERS_NAMES.PERIOD_FROM]: string;
  [FILTERS_NAMES.PERIOD_TO]: string;
};

export const filtersInitData: FiltersDataType = {
  [FILTERS_NAMES.STATUS]: [],
  [FILTERS_NAMES.NEWMESSAGES]: false,
  [FILTERS_NAMES.ONLYMYQUOTE]: false,
  [FILTERS_NAMES.SHOWCLOSING]: false,
  [FILTERS_NAMES.SWIFT]: '',
  [FILTERS_NAMES.QUOTE_ID]: '',
  [FILTERS_NAMES.TYPE]: OperationTypeOptions[0],
  [FILTERS_NAMES.COUNTRY]: { value: '', label: '' },
  [FILTERS_NAMES.SBERCLIENT]: SberClientsOptions[0],
  [FILTERS_NAMES.CLIENT]: { value: '', label: '' },
  [FILTERS_NAMES.AGENT]: { value: '', label: '' },
  [FILTERS_NAMES.MODERATOR]: { value: '', label: '' },
  [FILTERS_NAMES.AMOUNT_FROM]: '',
  [FILTERS_NAMES.AMOUNT_TO]: '',
  [FILTERS_NAMES.CURRENCY]: { value: '', label: '' },
  [FILTERS_NAMES.PERIOD_FROM]: '',
  [FILTERS_NAMES.PERIOD_TO]: '',
};
